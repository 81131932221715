import { Route, Switch } from 'react-router-dom';
import NotFound from '@super-template/core/src/NotFound';
import * as home from './search';

export default function Routes() {
  return (
    <Switch>
      <Route {...home} />
      <Route component={NotFound} />
    </Switch>
  );
}
