import { Link, ListItemButton, ListItemIcon } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { styled } from '@mui/material/styles';
import { ChevronRight } from '@mui/icons-material';
import { useContext } from 'react';
import { SiteContext } from '../../../../utils';
import { SERVICES_CURRENT_VALUES } from '../../../../constants';

const StyledItem = styled(ListItemButton)(({ theme }) => ({
  padding: '6px 11px 5px 11px',
  color: theme.palette.text.secondary,
  textDecorationColor: 'transparent',
  justifyContent: 'start',
  margin: '10px 0 0 10px',
  fontSize: theme.typography.body2.fontSize,
  textTransform: 'none',
  '&:hover,&:focus': {
    textDecoration: 'underline',
  },
  '& > span': { overflow: 'visible' },
  '&.Mui-selected': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.light,
    'span:after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.palette.secondary.light,
      width: '100%',
      position: 'absolute',
      height: '7px',
      top: '100%',
      zIndex: '-1',
    },
    '&:focus span:after': {
      backgroundColor: 'rgba(1, 115, 128, 0.2)',
    },
    '&:hover span:after': {
      backgroundColor: 'rgba(1, 115, 128, 0.12)',
    },
    '& .MuiListItemIcon-root': {
      stroke: theme.palette.primary.main,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '30px',
    stroke: theme.palette.text.primary,
  },
  [theme.breakpoints.up('m')]: {
    textTransform: 'lowercase',
    margin: 'auto',
    fontSize: theme.typography.fontSize,
    '&.Mui-selected': {
      backgroundColor: '#f0f3f4',
      'span:after': {
        backgroundColor: '#f0f3f4',
      },
    },
  },
}));
const LocalizedLink = ({ mobile, siteName }) => {
  const intl = useIntl();
  const { lang, currentSite } = useContext(SiteContext);

  const url = intl.formatMessage(messages[siteName + 'Url']);

  const selected = currentSite
    ? SERVICES_CURRENT_VALUES?.[siteName] === currentSite
    : false;
  return (
    <StyledItem
      component={Link}
      target={'_parent'}
      lang={lang}
      href={url}
      aria-current={selected}
      selected={selected}
    >
      {mobile && (
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
      )}
      <FormattedMessage {...messages[siteName]} />
    </StyledItem>
  );
};

export default LocalizedLink;
