import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, EMPTY_RESULTS } from '../constants';
import { noneSelected } from 'utils';

const useSearch = ({ params }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [results, setResults] = useState(EMPTY_RESULTS);

  useEffect(() => {
    //do query only if query param exists
    const fetchData = async () => {
      setIsLoading(true);
      if (params?.query && params?.query !== '' && !noneSelected(params)) {
        //set youth ideas false, because it will be removed
        await axios
          .get(`${BASE_URL}`, {
            method: 'get',
            params: { ...params },
          })
          .then((response) => {
            if (params.currentPage > 1) {
              setResults((prevResults) => ({
                ...prevResults,
                items: [...prevResults.items, ...response.data.items],
              }));
            } else {
              setResults(response.data);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setError(error);
            setIsLoading(false);
          });
      } else {
        setResults({ ...EMPTY_RESULTS });
        setIsLoading(false);
      }
    };
    const timeOutId = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timeOutId);
    //eslint-disable-next-line
  }, [params]);
  return {
    isLoading,
    results,
    error,
  };
};
export default useSearch;
