import { useState } from 'react';

const useDrawers = () => {
  const initialState = { mobile: false, search: false };
  const [openDrawers, setOpenDrawers] = useState(initialState);
  const toggle = (drawerName) => {
    setOpenDrawers((prev) => {
      return { ...initialState, [drawerName]: !prev[drawerName] };
    });
  };
  return {
    openDrawers,
    toggle: (name) => toggle(name),
  };
};
export default useDrawers;
