import { List } from '@mui/material';
import LocalizedLink from './LocalizedLink';
import styled from '@emotion/styled';
import { SERVICES } from '../../../../constants';
import { useIntl } from 'react-intl';
import messages from '../../../../messages';

const StyledList = styled(List)(({ theme, mobile }) => ({
  display: 'flex',
  flexDirection: mobile ? 'column' : 'row',
  padding: '8px',
  backgroundColor: mobile ? theme.palette.secondary.dark : 'transparent',
}));
const NavigationLinks = ({ mobile }) => {
  const intl = useIntl();
  return (
    <StyledList
      mobile={mobile}
      component={'nav'}
      aria-label={intl.formatMessage(messages.services)}
    >
      <LocalizedLink mobile={mobile} siteName={'demokratia'} />
      {SERVICES.map((service) => (
        <LocalizedLink
          key={'link-' + service}
          mobile={mobile}
          siteName={service}
        />
      ))}
      <LocalizedLink mobile={mobile} siteName={'vaalit'} />
      <LocalizedLink mobile={mobile} siteName={'puoluerekisteri'} />
    </StyledList>
  );
};
export default NavigationLinks;
