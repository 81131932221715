import { Close, Search as SearchIcon } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import messages from 'messages';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DEFAULT_QUERY,
  SERVICES,
  SERVICES_CURRENT_VALUES,
} from '../../../../constants';
import useSearch from '../../../../hooks/useSearch';
import {
  adjustIframeHeight,
  DrawerWrapper,
  noneSelected,
  SiteContext,
  ToggleButton,
} from '../../../../utils';
import Filters from './Filters';
import Input from './Input';
import Instructions from './Instructions';
import Results from './Results';

import { useTheme } from '@emotion/react';
import { DrawerContext } from '../../Page';

const styles = {
  toggle: {
    textTransform: 'none',
    border: 'none',
    color: 'text.secondary',
    textDecoration: 'underline',
  },
  close: {
    display: 'flex',
    flexDirection: 'column',
    color: 'text.primary',
    position: 'absolute',
    right: '20px',
    top: '30px',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
    border: 0,
    borderTop: '1px solid #eee',
  },
  container: {
    width: '80%',
  },
};
const Search = () => {
  const intl = useIntl();
  const { currentSite } = useContext(SiteContext);
  const defaultFilter = {};
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY,
    ...defaultFilter,
  });
  const { isLoading, results, error } = useSearch({ params: queryParams });
  const theme = useTheme();
  const useMobileStyle = !useMediaQuery(theme.breakpoints.up('m'));
  const { openDrawers, toggle } = useContext(DrawerContext);

  const toggleSearch = (e) => {
    adjustIframeHeight(!openDrawers.search);
    toggle('search');
  };
  useEffect(() => {
    Object.values(SERVICES_CURRENT_VALUES).indexOf(currentSite) === -1
      ? SERVICES.forEach((service) => (defaultFilter[service] = true))
      : SERVICES.forEach((service) =>
          SERVICES_CURRENT_VALUES?.[service] === currentSite
            ? (defaultFilter[service] = true)
            : (defaultFilter[service] = false)
        );
    // reset queryParams if page changes (as in previous version of supersearch)
    setQueryParams({
      ...DEFAULT_QUERY,
      ...defaultFilter,
    });
    // eslint-disable-next-line
  }, [currentSite]);
  return (
    <>
      <ToggleButton
        size={'medium'}
        sx={styles.toggle}
        component={Button}
        open={openDrawers.search}
        Icon={SearchIcon}
        onClick={toggleSearch}
        aria-label={
          openDrawers.search
            ? intl.formatMessage(messages.closeSearch)
            : intl.formatMessage(messages.openSearch)
        }
        startIcon={!useMobileStyle && <SearchIcon sx={{ fontSize: '22px' }} />}
      >
        <FormattedMessage {...messages.search} />
      </ToggleButton>
      <DrawerWrapper open={openDrawers.search} onClose={toggleSearch}>
        <Container maxWidth={'l'} sx={!useMobileStyle ? styles.container : {}}>
          <Input
            key={'search-input'}
            params={queryParams}
            setParams={setQueryParams}
          />
          <Instructions />
          <Filters
            key={'search-filters'}
            params={queryParams}
            setParams={setQueryParams}
          />
          {!useMobileStyle && (
            <IconButton
              sx={styles.close}
              aria-label={intl.formatMessage(messages.closeSearch)}
              onClick={toggleSearch}
            >
              <Close />
              <Typography variant={'body2'}>
                <FormattedMessage {...messages.close} />
              </Typography>
            </IconButton>
          )}
          <Divider sx={styles.divider} aria-hidden={true} />
          {queryParams?.query &&
          !noneSelected(queryParams) &&
          results &&
          !error ? (
            <Results
              term={queryParams?.query}
              results={results}
              isLoading={isLoading}
            />
          ) : (
            !isLoading &&
            error && (
              <Alert
                sx={{ marginBottom: '30px' }}
                role={'presentation'}
                severity={'error'}
              >
                <FormattedMessage {...messages.fetchError} />
              </Alert>
            )
          )}
          {isLoading && <LinearProgress />}
          {queryParams?.query &&
            results?.items?.length < results?.totalElements && (
              <Button
                sx={{ margin: '10px 0 50px' }}
                onClick={() =>
                  setQueryParams({
                    ...queryParams,
                    currentPage: queryParams.currentPage + 1,
                  })
                }
              >
                <Typography variant={'body2'}>
                  {' '}
                  <FormattedMessage {...messages.showMore} />
                </Typography>
              </Button>
            )}
        </Container>
      </DrawerWrapper>
    </>
  );
};
export default Search;
