import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import messages from '../../../../messages';

const styles = {
  marginBottom: '20px',
  marginTop: '30px',
  height: '70px',
  '.MuiInputBase-root, .MuiSvgIcon-root': {
    fontSize: '30px',
  },
  '.MuiSvgIcon-root,.MuiOutlinedInput-notchedOutline': {
    color: 'primary.main',
    borderColor: 'primary.main',
  },
  '.MuiSvgIcon-root': {
    transform: 'scale(1.5)',
    paddingRight: '10px',
  },
};
const Input = ({ params, setParams }) => {
  const intl = useIntl();
  const handleChange = (e) => {
    e.stopPropagation();
    setParams({ ...params, query: e.target.value, currentPage: 1 });
  };
  return (
    <TextField
      sx={styles}
      placeholder={intl.formatMessage(messages.search)}
      value={params?.query ?? ''}
      fullWidth
      autoFocus={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
      inputProps={{ 'aria-label': intl.formatMessage(messages.search) }}
      onChange={handleChange}
    />
  );
};
export default Input;
