import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function useSetDocumentTitle(pageTitle) {
  const intl = useIntl();

  useEffect(() => {
    document.title = pageTitle ? `${pageTitle}` : 'superhaku';
  }, [intl, pageTitle]);
}
