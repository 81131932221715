import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../../../messages';
import { SERVICES } from '../../../../constants';
import { noneSelected } from 'utils';

const styles = {
  width: '100%',
  legend: { fontSize: '18px', color: 'black', marginBottom: '15px' },
  '.MuiFormControlLabel-label': { fontSize: '18px' },
  '.MuiFormControlLabel-root': { marginLeft: '0px', color: '#555555' },
  '.Mui-checked+.MuiFormControlLabel-label': { color: 'primary.main' },
  '.MuiCheckbox-root': {
    paddingLeft: '7px',
    '&:not(.Mui-checked)': {
      color: '#d3d3d3',
      '&:after': {
        content: "''",
        display: 'block',
        height: '24px',
        width: '24px',
        backgroundColor: '#d3d3d3',
        position: 'absolute',
        zIndex: '-1',
      },
    },
  },
  '.MuiFormGroup-root': { flexDirection: 'row' },
};

const Filters = ({ params, setParams }) => {
  const intl = useIntl();
  const handleChange = (e) => {
    setParams({
      ...params,
      query: params?.query ?? undefined,
      currentPage: 1,
      [e.target.name]: !params[e.target.name],
    });
  };
  const selectAll = () => {
    const servicesTrue = {};
    SERVICES.forEach((service) => (servicesTrue[service] = true));
    setParams({ ...params, ...servicesTrue });
  };
  return (
    <>
      <FormControl
        required
        component="fieldset"
        error={noneSelected(params)}
        sx={styles}
      >
        <FormLabel component="legend">
          <FormattedMessage {...messages.search} />:
        </FormLabel>
        <FormGroup>
          {SERVICES.map((service) => (
            <FormControlLabel
              key={service}
              control={
                <Checkbox
                  size="large"
                  checked={params[service]}
                  onChange={handleChange}
                  name={service}
                />
              }
              label={intl.formatMessage(messages[service])}
            />
          ))}
        </FormGroup>
        {Object.entries(params).some(
          ([key, value]) => SERVICES.indexOf(key) >= 0 && !value
        ) && (
          <FormControlLabel
            control={
              <Checkbox
                size="large"
                onChange={(e) => e.target.checked && selectAll()}
                name="searchAll"
              />
            }
            label={intl.formatMessage(messages.searchAll)}
          />
        )}
        <FormHelperText
          sx={{ fontSize: '14px', marginLeft: 0 }}
          error={noneSelected(params)}
        >
          {noneSelected(params) && (
            <FormattedMessage {...messages.selectService} />
          )}
        </FormHelperText>
      </FormControl>
    </>
  );
};
export default Filters;
