import { SERVICES, TOOLBAR_HEIGHT } from './constants';
import { Button, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import * as queryString from 'query-string';
import SE from 'translations/sv-SE.json';
import FI from 'translations/fi-FI.json';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  padding: '6px 10px',
  stroke: theme.palette.text.secondary,
  strokeWidth: 0.5,
  textDecoration: 'none',
  top: '0px',
  minHeight: '35px',
  marginRight: '8px',
  '&.open': {
    backgroundColor: theme.palette.secondary.dark,
    '&:after': {
      content: "''",
      display: 'block',
      backgroundColor: theme.palette.secondary.dark,
      width: '100%',
      position: 'absolute',
      height: '5px',
      top: '100%',
      zIndex: '-1',
    },
  },
  [theme.breakpoints.up('m')]: {
    textTransform: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    top: 0,
    fontWeight: '400',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': { textDecoration: 'underline' },
    '&.open': {
      color: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
      fontWeight: '700',
      backgroundColor: '#f0f3f4',
      textDecoration: 'none',
      '&:after': {
        backgroundColor: '#f0f3f4',
      },
    },
  },
}));
export const ToggleButton = ({ open, Icon, children, ...props }) => {
  return (
    <StyledButton
      variant={'contained'}
      color={'secondary'}
      className={open && 'open'}
      role={'button'}
      aria-expanded={open}
      endIcon={
        !props.startIcon ? Icon ? open ? <Close /> : <Icon /> : null : null
      }
      {...props}
    >
      {children}
    </StyledButton>
  );
};
export const DrawerWrapper = ({ children, ...props }) => {
  return (
    <Drawer
      anchor={'top'}
      transitionDuration={100}
      sx={{
        top: TOOLBAR_HEIGHT,
        position: 'absolute',
        '.MuiBackdrop-root': {
          position: 'absolute',
        },
        '.MuiPaper-root': {
          position: 'relative',
          maxHeight: '80vh',
          '&::-webkit-scrollbar': {
            width: '20px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'gray',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'lightgrey',
          },
        },
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
};
export const adjustIframeHeight = (isOpen) => {
  window.parent.postMessage(isOpen ? '100vh' : TOOLBAR_HEIGHT, '*');
};

export const SiteContext = createContext();

export const SiteContextProvider = ({ setLocale, children }) => {
  //const [, setLocale] = useLocale();
  const location = useLocation();

  const [params, setParams] = useState({ lang: '', currentSite: '' });
  useEffect(() => {
    //slice 2 first characters ("/?") away since search params are  read as hash (#/?lan=x&site=y)
    const info = queryString.parse(location?.hash?.slice(2));
    setParams({ lang: info?.lan, currentSite: info?.site });
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (params) {
      setLocale(
        params.lang === 'sv'
          ? { locale: 'sv-SE', messages: SE }
          : { locale: 'fi-FI', messages: FI }
      );
    }
    //eslint-disable-next-line
  }, [params]);
  return <SiteContext.Provider value={params}>{children}</SiteContext.Provider>;
};

export const noneSelected = (params) => {
  let res = true;
  SERVICES.forEach((item) => {
    if (params?.[item]) {
      res = false;
    }
  });
  return res;
};
