import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import './main.css';

export default function Theme({ children }) {
  const theme = useMemo(
    () =>
      createTheme({
        shadows: [...Array(20).fill('none')],
        palette: {
          mode: 'light',
          background: {
            default: 'transparent',
          },
          primary: {
            main: '#017380',
          },
          secondary: {
            main: '#fbfbfb',
            light: '#fdffff',
            dark: '#e8e8e8',
          },
          text: {
            secondary: '#313131',
            primary: '#000000',
          },
        },
        overrides: {
          MuiAlert: {
            message: {
              width: '100%',
            },
          },
        },
        typography: {
          fontFamily: "'PT Sans','Trebuchet MS','Helvetica',sans-serif",
          fontSize: 13,
          body2: {
            fontSize: 16,
          },
        },
        breakpoints: {
          values: {
            l: 1100,
            m: 950,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 0,
                textTransform: 'none',
                fontWeight: 'bold',
              },
            },
          },
        },
      }),
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
