import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../../../messages';
import { Button, Collapse, Typography } from '@mui/material';
import { useState } from 'react';

const Instructions = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <Button
        variant={'outlined'}
        aria-label={
          !isOpen
            ? intl.formatMessage(messages.openInstructions)
            : intl.formatMessage(messages.closeInstructions)
        }
        aria-expanded={isOpen}
        size={'medium'}
        sx={{ borderRadius: '5px', float: 'right' }}
        onClick={toggle}
      >
        <FormattedMessage
          {...messages[isOpen ? 'closeInstructions' : 'instructionsBtn']}
        />
      </Button>
      <Collapse
        in={isOpen}
        timeout={100}
        unmountOnExit
        sx={{
          padding: '20px',
          float: 'left',
          margin: '5px 0 30px',
          borderRadius: '5px',
          backgroundColor: 'rgba(212,212,212,0.29)',
        }}
      >
        <Typography variant={'body2'}>
          <FormattedMessage {...messages.instructions} />
        </Typography>
      </Collapse>
    </>
  );
};

export default Instructions;
