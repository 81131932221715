import { useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../messages';
import NavigationLinks from './NavigationLinks';
import { useContext } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  adjustIframeHeight,
  DrawerWrapper,
  ToggleButton,
} from '../../../../utils';
import { DrawerContext } from '../../Page';
import { useTheme } from '@emotion/react';

const Navigation = () => {
  const theme = useTheme();
  const useDrawerNav = !useMediaQuery(theme.breakpoints.up('m'));
  const { openDrawers, toggle } = useContext(DrawerContext);
  const toggleNav = () => {
    adjustIframeHeight(!openDrawers.mobile);
    toggle('mobile');
  };
  return (
    <>
      {useDrawerNav ? (
        <>
          <ToggleButton
            onClick={toggleNav}
            open={openDrawers.mobile}
            Icon={KeyboardArrowDown}
          >
            <FormattedMessage {...messages.services} />
          </ToggleButton>
          <DrawerWrapper open={openDrawers.mobile} onClose={toggleNav}>
            <NavigationLinks mobile />
          </DrawerWrapper>
        </>
      ) : (
        <NavigationLinks />
      )}
    </>
  );
};
export default Navigation;
