import { Card, CardContent, CardHeader, Link, Typography } from '@mui/material';
import { MAX_HIGHLIGHTS } from '../../../../../constants';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import messages from '../../../../../messages';

const styles = {
  textDecoration: 'none',
  fontSize: '16px',
  margin: '40px 0',
  display: 'block',
  '& > div': {
    padding: '0!important',
  },
  '& .MuiCardHeader-content': {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'start',
    alignItems: 'center',
  },
  '& .MuiCardHeader-subheader': {
    color: '#979797',
    marginRight: '5px',
    fontSize: 'inherit',
  },
  '& .MuiCardHeader-title': {
    color: 'primary.main',
    fontWeight: 'bold',
    fontSize: 'inherit',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  '& h3': {
    fontSize: '24px',
    color: 'primary.main',
    margin: 0,
  },
  '.MuiTypography-body2': {
    borderLeft: 'solid rgba(128,128,128,0.14) 5px',
    paddingLeft: '10px',
    marginTop: '15px',
    '& span': {
      backgroundColor: 'rgba(255,255,0,0.24)',
    },
  },
};

const Part = ({ highlight, children }) =>
  highlight ? <span>{children}</span> : <>{children}</>;

const Item = ({ item }) => {
  const intl = useIntl();
  return (
    <Card sx={styles} component={Link} href={item.webLink} target={'_blank'}>
      <CardHeader
        title={intl.formatMessage(messages[item.type.split('.')[0]])}
        subheader={format(new Date(item.date), 'dd.MM.yyyy')}
      />
      <CardContent>
        <Typography gutterBottom variant="h3">
          {item.name}
        </Typography>
        {item.highlights
          .sort((x, y) => y.length - x.length)
          .slice(0, MAX_HIGHLIGHTS)
          .map((hl, index) => (
            <Typography key={index + '-hl'} variant="body2" component="p">
              {hl.map((chunk, i) => (
                <Part highlight={chunk.highlight} key={index + '-chunk-' + i}>
                  {chunk.text}
                </Part>
              ))}
            </Typography>
          ))}
      </CardContent>
    </Card>
  );
};
export default Item;
