import { Alert, AlertTitle, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export default function ErrorFallback({ error }) {
  const handleFocus = (event) => event.target.select();

  return (
    <Alert severity="warning">
      <AlertTitle>
        <FormattedMessage defaultMessage="Jotain meni pieleen" />
      </AlertTitle>
      <TextField
        multiline
        maxRows={4}
        value={error.stack ?? error}
        fullWidth
        readOnly
        onFocus={handleFocus}
      />
    </Alert>
  );
}
