import AppBar from './AppBar';
import useSetDocumentTitle from './useSetDocumentTitle';
import { createContext, useContext, useEffect } from 'react';
import useDrawers from '../../hooks/useDrawers';
import { SiteContext } from '../../utils';

export const DrawerContext = createContext();

export default function Page({ title, children }) {
  useSetDocumentTitle(title);
  const drawers = useDrawers();
  const { lang } = useContext(SiteContext);

  useEffect(() => {
    document.documentElement.lang = lang ?? 'fi-FI';
  }, [lang]);
  return (
    <DrawerContext.Provider value={drawers}>
      <AppBar />
      {children}
    </DrawerContext.Provider>
  );
}
