import { Layout } from '@super-template/core/src/LayoutFallback';
import ErrorFallback from '@super-template/lab/src/ErrorFallback';
import Theme from '@super-template/theme';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { SiteContextProvider } from '../utils';
import Page from './layouts/Page';
import Routes from './Routes';
import FI from 'translations/fi-FI.json';

export default function App() {
  const [locale, setLocale] = useState({ lang: 'fi-FI', messages: FI });
  return (
    <Theme>
      <IntlProvider
        locale={locale.locale ?? 'fi-FI'}
        messages={locale.messages}
        defaultLocale="fi-FI"
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Layout FallbackComponent={Page}>
            <BrowserRouter>
              <SiteContextProvider setLocale={setLocale}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <Routes />
                </QueryParamProvider>
              </SiteContextProvider>
            </BrowserRouter>
          </Layout>
        </ErrorBoundary>
      </IntlProvider>
    </Theme>
  );
}
