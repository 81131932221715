import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from '@super-template/messages';

export default function BackButton(props) {
  const history = useHistory();

  return (
    <Button
      onClick={history.goBack}
      children={<FormattedMessage {...messages.back} />}
      {...props}
    />
  );
}
