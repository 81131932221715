import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../../messages';
import Item from './Item';

const Results = ({ results, term, isLoading }) => {
  return (
    <div aria-live="polite">
      <Typography variant={'body2'} sx={{ fontSize: '18px' }}>
        {results.totalElements > 0 ? (
          <FormattedMessage
            {...messages.resultCount}
            values={{ term: term, resultCount: `${results.totalElements}` }}
          />
        ) : (
          term &&
          !isLoading && (
            <FormattedMessage
              {...messages.noResults}
              values={{ term: `${term}` }}
            />
          )
        )}
      </Typography>
      {results?.items?.map((item) => {
        return <Item key={'item-' + (item.id ?? item.webLink)} item={item} />;
      })}
    </div>
  );
};
export default Results;
